import(/* webpackMode: "eager" */ "/var/www/travelthru.com/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/src/components/GlobalCoverage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/src/components/GradientBG.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/src/components/home/ReadMoreReviewsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/src/components/home/WhatWeDoSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/src/components/home/YourTravelPartnerSection.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/travelthru.com/src/styles/booking.css");
;
import(/* webpackMode: "eager" */ "/var/www/travelthru.com/src/styles/small-gradient-bg.css");
